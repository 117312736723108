import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styles from './styles/updateProfile.scss';
import { useTranslation } from 'react-i18next';
import {
	eventType,
	rolType,
	iconArrowDown,
	PROGRAM_SUGGEST_MAX_LENGTH,
	NUMBER_SEMESTER_LENGTH
} from '../../../utils/constans';
import { ImpulseSpinner } from 'react-spinners-kit';
import {
	searchProgram,
	EditProfileNew,
	fetchRoles,
	refreshHome
} from '../../actions';

import { useIsValidEmail } from '../../hooks/useValidateEmail';

const UpdateProfile = ({
	sendEventPopup,
	searchProgram,
	programs,
	EditProfileNew,
	fetchRoles,
	closePopUp,
	refreshHome,
	exploration
}) => {
	const [role, setRole] = useState();
	const [roles, setRoles] = useState();
	const [firstName, setFirstName] = useState();
	const [lastName, setLastName] = useState();
	const [semester, setSemester] = useState();
	const [program, setProgram] = useState();
	const [otherEmail, setOtherEmail] = useState();
	const [searchs, setSearchs] = useState([]);
	const [drop, setDrop] = useState(false);
	const [error, setError] = useState(false);
	const [errorEmail, setErrorEmail] = useState(false);
	const [loading, setLoading] = useState(false);
	const { t, i18n } = useTranslation();
	const [isProxy, setIsProxy] = useState(false);
	const isValidEmail = useIsValidEmail(otherEmail);

	useEffect(() => {
		if (!roles) {
			handleRoles();
		}
		env.proxy ? setIsProxy(true) : setIsProxy(false);
	}, []);

	useEffect(() => {
		if (programs) {
			setSearchs(programs);
			setDrop(true);
		}
	}, [programs]);

	const handleRoles = async () => {
		const roles = await fetchRoles();
		setRoles(roles);
	};

	const handleSearch = async (query) => {
		try {
			setProgram(query);
			searchProgram(query);
		}
		catch (error) {
			console.error(error);
		}
	};
	const handleProgram = (program) => {
		setProgram(program);
		setDrop(false);
	};
	const handleSemester = (event) => {
		let number = event.target.value;
		if (number.toString().length <= NUMBER_SEMESTER_LENGTH) setSemester(number);
	};


	const handleSubmit = async() => {
		if(!firstName ||
			!lastName ||
			!role ||
			(role !== rolType.admin && !program) ||
			(otherEmail && otherEmail !== '' && !isValidEmail)) {
			setError(true);
		} else {
			const params = {
				firstName,
				lastName,
				role,
				mayor: program ? program.name ? program.name : program : null,
				semester,
				programImportId: program && program.id ? program.id : null,
				otherEmail: otherEmail

			};
			EditProfileNew(params);
			sendEventPopup(eventType.signUpCompleted);
			refreshHome(params.mayor);
			closePopUp();
		}
	};

	return (
		<div className={!isProxy ? styles.containerProfile : isProxy && role && role !== rolType.admin ? `${styles.containerProfileProxy} ${styles.containerProfileStudent}` : styles.containerProfileProxy}>
			<h2 className={styles.fontTitle}>{t('titleUpdateProfile')}</h2>
			{isProxy &&
				<div>
					<div className={styles.containerProgram}>
						<div className={styles.containerWidth100}>
							<p className={`${styles.fontp} ${styles.margin}`}>{t('emailInput')}</p>
							<div className={!isValidEmail && otherEmail && otherEmail !== '' ? `${styles.input_Rectangle} ${styles.alertBorderNew}` : styles.input_Rectangle}>
								<input
									name='otherEmail'
									type="input"
									onChange={(event) => setOtherEmail(event.target.value)}
								/>
							</div>
						</div>
					</div>
					{!isValidEmail && otherEmail && otherEmail !== '' &&
						<p className={styles.errorMessageParagraph}>{t('errorEmailLogin')}</p>
					}
				</div>
			}
			<div>
				<div className={styles.gridName}>
					<div className={styles.inputName}>
						<p className={styles.fontp}>{t('inputFirstName')}</p>
						<div className={error && !firstName ? `${styles.input_Rectangle} ${styles.alertBorderNew}` : styles.input_Rectangle}>
							<input
								name='firstName'
								data-test="inputFirstName"
								type='input'
								onChange={(event) => setFirstName(event.target.value)}
							/>
						</div>
					</div>
					<div className={styles.inputName}>
						<p className={styles.fontp}>{t('inputLastName')}</p>
						<div className={error && !lastName ? `${styles.input_Rectangle} ${styles.alertBorderNew}` : styles.input_Rectangle}>
							<input
								name='lastName'
								data-test="inputLastName"
								type='input'
								onChange={(event) => setLastName(event.target.value)}
							/>
						</div>
					</div>
				</div>
				{error && (!firstName ||
					!lastName) &&
					<p className={styles.errorMessageParagraph}>{t('errorUpdateName')}</p>
				}
			</div>
			<div>
				<p className={styles.fontp}>{t('textChooseRole')}</p>
				<div className={!isProxy ? styles.containerMultiSelect : `${styles.containerMultiSelect} ${styles.containerMultiSelectProxy}`}>
					{roles && roles.map((roles) => {
						return (
							<div key={roles.identifier} className={styles.multiSelect}>
								<input
									className={error && !role ? styles.alertBorderNew : null}
									data-test={`${roles.identifier}`}
									name="role"
									type='radio'
									onChange={() => setRole(roles.identifier)}
								/>
								<label htmlFor={roles.identifier}>{roles.label}</label>
							</div>
						);
					})}
				</div>
				{error && !role &&
					<p className={styles.errorMessageParagraph}>{t('errorChooseRole')}</p>
				}
			</div>
			{role !== rolType.admin && role &&
				<div className={styles.containerProgram}>
					<div className={role === rolType.student ? styles.containerWidth80 : styles.containerWidth100}>
						<p className={`${styles.fontp} ${styles.margin}`}>{t('textProgram')}</p>
						<div className={error && !program ? `${styles.input_Rectangle} ${styles.alertBorderNew}` : styles.input_Rectangle}>
							<input
								className={styles.input_program}
								value={program && program.name ? (program.name.length > PROGRAM_SUGGEST_MAX_LENGTH ? `${program.name.slice(0, PROGRAM_SUGGEST_MAX_LENGTH)}...` : program.name) : program}
								name='search'
								autoComplete='off'
								data-test="InputSearchProgram"
								type="text"
								placeholder={t('placeholderProgram')}
								onChange={(event) => handleSearch(event.target.value)}
							/>
							<img src={iconArrowDown} className={styles.iconArrowDown} onClick={() => searchs.length ? setDrop(true) : setDrop(false)} />
							{drop && searchs.length ?
								<>
									<div className={styles.fixedClosePop} onClick={() => setDrop(false)}></div>
									<div className={styles.dropWhite}>
										<div className={styles.dropWhiteContainer}>
											{searchs.map((program, index) => {
												return (
													<div
														className={styles.dropWhiteItem}
														data-test={`program${index}`}
														onClick={() => handleProgram(program)}
													>
														{program.name}
													</div>
												);
											})}
										</div>
									</div>
								</>
								:
								null
							}
						</div>
					</div>
					{role === rolType.student &&
						<div className={styles.containerSemester} >
							<p className={`${styles.fontp} ${styles.margin}`}>{t('inputSemester')}</p>
							<span className={styles.number_wrapper}>
								<input
									placeholder="0"
									data-test="inputSemester"
									value={semester}
									type="number"
									min="1" max="12"
									maxLength='2'
									onChange={handleSemester}
								/>
							</span>
						</div>
					}
				</div>
			}
			<div data-test="buttonUpdateProfile" className={`${styles.button_blue} ${styles.buttonPosition}`} onClick={!loading ? handleSubmit : null}>
				{!loading ?
					t('buttonEndForm')
					:
					<ImpulseSpinner
						size={40}
						color="white"
						frontColor="white"
						backColor='white'
						loading={loading}
					/>
				}
			</div>
		</div>
	);
};

const mapStateToProps = ({ profileHome, exploration }) => {
	return {
		programs: profileHome.programs,
		exploration,
	};
};

export default withRouter(connect(mapStateToProps, {
	searchProgram,
	EditProfileNew,
	fetchRoles,
	refreshHome
})(UpdateProfile));