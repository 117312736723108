import React, { useEffect, useRef, useState } from 'react';
import styles from './styles/profileSettings.scss';
import { iconCameraBlack, rolType } from '../../../../utils/constans';
import { useTranslation } from 'react-i18next';
import useFormFileds from '../../../hooks/useFormFields';
import InputGroup, { types } from '../../shared/InputGroup';
import Button, { appearances } from '../../shared/Button';
import { EditProfileNew, searchProgram } from '../../../actions';
import useOptionDropDown from '../../../hooks/useOptionDropDown';
import DropDown from '../../shared/DropDown';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { searchProgramByName } from '../../../actions/ProfilesHomeActions';
import { editProfile } from '../../../actions/profileActions';
import { ImpulseSpinner } from 'react-spinners-kit';

const ProfileSettings = ({ profile, EditProfileNew, searchProgram }) => {
	const { t } = useTranslation();
	const [image, setImage] = useState();
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [profileTypesList, setProfileTypesList] = useState([
		{
			value: rolType.admin,
			label: t('roleAdmin'),
		},
		{
			value: rolType.graduate,
			label: t('titleGraduate'),
		},
		{
			value: rolType.teacher,
			label: t('titleTeacher'),
		},
		{
			value: rolType.student,
			label: t('titleStudent'),
		},
	]);
	const [missingFields, setMissingFields] = useState([]);
	const [
		roleOptions,
		selectedRole,
		handleRole,
		resetSelectedRole,
		defaultRole,
	] = useOptionDropDown(profileTypesList, false);
	const [programs, setPrograms] = useState([]);
	const [program, setProgram] = useState();

	const { fields, handleOnchage } = useFormFileds({
		email: '',
		firstName: '',
		lastName: '',
		description: '',
		semester: '',
	});

	const fileInputRef = useRef(null);

	useEffect(() => {
		handleSearch('a');
	}, []);

	useEffect(() => {
		if (profile) {
			handleOnchage(profile.profile.data.email, 'email');
			handleOnchage(profile.profile.data.firstName, 'firstName');
			handleOnchage(profile.profile.data.lastName, 'lastName');
			handleOnchage(profile.profile.data.description, 'description');
			handleOnchage(profile.profile.data.semester || '', 'semester');
			const programFromProfile = {
				value: profile.profile.data.programImportId,
				label: profile.profile.data.mayor,
			};
			setProgram(programFromProfile);

			handleRole(
				profileTypesList.find(
					(role) => role.value === profile.profile.data.identifier
				)
			);
			setIsButtonDisabled(false);
		}
	}, [profile]);

	const handleFileUpload = () => {
		fileInputRef.current.click();
	};

	const submit = async () => {
		try {
			if (validateFields()) {
				return;
			}
			let params = {};
			params['firstName'] = fields.firstName;
			params['lastName'] = fields.lastName;
			params['description'] = fields.description;
			params['role'] = selectedRole.value;
			if (selectedRole.value === rolType.student) {
				params['programImportId'] = program.value;
				params['mayor'] = program.label;
				params['semester'] = fields.semester;
			} else if (
				selectedRole.value === rolType.graduate ||
				selectedRole.value === rolType.teacher
			) {
				params['programImportId'] = program.value;
				params['mayor'] = program.label;
			}
			setIsButtonDisabled(true);
			setIsLoading(true);
			await editProfile(profile.profile.data.id, params, image);
			setIsButtonDisabled(false);
			setIsLoading(false);
		} catch (err) {
			console.error(err);
		}
	};

	const handleSearch = (query) => {
		searchProgramByName(query).then((programs) => {
			const programsArray = [];
			if (programs) {
				programs.map((program) => {
					programsArray.push({
						label: program.name,
						value: program.id,
					});
				});
			}
			setPrograms(programsArray);
		});
	};

	const selectProgram = (value) => {
		setProgram(value);
	};

	const handleInputChange = (query) => {
		if (query == '') {
			// NO SEARCH OR SELECT NEW TERM
		} else {
			handleSearch(query);
		}
	};

	const handleFileSelected = (e) => {
		const selectedFile = e.target.files[0];
		if (selectedFile) {
			setImage(selectedFile);
		}
	};

	const validateFields = () => {
		// Create an array to store missing fields
		const requiredFields = [];

		// Check if the selectedRole is student and program is missing
		if (
			[rolType.student, rolType.teacher, rolType.graduate].includes(
				selectedRole.value
			) &&
			!program
		) {
			requiredFields.push('program');
		}

		// Check if selectedRole is student and semester is missing
		if (selectedRole.value === rolType.student && !fields.semester) {
			requiredFields.push('semester');
		}

		// Check for missing values in fields object
		for (const field in fields) {
			if (!fields[field] && field !== 'semester') {
				requiredFields.push(field);
			}
		}

		// If there are any missing fields, return false; otherwise, return true
		setMissingFields(requiredFields);
		return requiredFields.length !== 0;
	};

	return (
		<div className={styles.mainContainer}>
			<div className={styles.row}>
				<div
					className={styles.columnInput}
					style={{ marginRight: '40px' }}
				>
					<p className={styles.inputTitle}>{t('textInputPhoto')}</p>
					<input
						type="file"
						accept="image/*"
						style={{ display: 'none' }}
						ref={fileInputRef}
						onChange={handleFileSelected}
					/>
					<Button
						height={'48px'}
						width={'100%'}
						appearance={appearances.secondary}
						borderRadius="6px"
						icon={iconCameraBlack}
						title={t('textChangePhotoProfile')}
						action={handleFileUpload}
					/>
					{image && (
						<p className={styles.pictureName}>{image.name}</p>
					)}
				</div>
				<div className={styles.columnInput}>
					<p className={styles.inputTitle}>{t('textEmail')}</p>
					<InputGroup
						isDisabled={true}
						inputName={'email'}
						style={{
							height: '48px',
							margin: '0px',
							fontSize: '16px',
						}}
						placeholder={`${t('textEmail')}...`}
						onChange={handleOnchage}
						value={fields.email}
					/>
				</div>
			</div>
			<div className={styles.row}>
				<div
					className={styles.columnInput}
					style={{ marginRight: '40px' }}
				>
					<p className={styles.inputTitle}>{t('inputFirstName')}</p>
					<InputGroup
						inputName={'firstName'}
						error={missingFields.includes('firstName')}
						style={{
							height: '48px',
							margin: '0px',
							fontSize: '16px',
						}}
						placeholder={`${t('inputFirstName')}...`}
						onChange={handleOnchage}
						value={fields.firstName}
					/>
				</div>
				<div className={styles.columnInput}>
					<p className={styles.inputTitle}>{t('inputLastName')}</p>
					<InputGroup
						inputName={'lastName'}
						error={missingFields.includes('lastName')}
						style={{
							height: '48px',
							margin: '0px',
							fontSize: '16px',
						}}
						placeholder={`${t('inputLastName')}...`}
						onChange={handleOnchage}
						value={fields.lastName}
					/>
				</div>
			</div>
			<div className={styles.row}>
				<div className={styles.columnInput}>
					<p className={styles.inputTitle}>
						{t('textDescriptionContent')}
					</p>
					<InputGroup
						inputName={'description'}
						error={missingFields.includes('description')}
						style={{
							height: '100px',
							margin: '0px',
							fontSize: '16px',
						}}
						type={types.textArea}
						placeholder={`${t('textDescriptionContent')}...`}
						onChange={handleOnchage}
						value={fields.description}
					/>
				</div>
			</div>
			<div className={styles.row}>
				<div className={styles.columnInput}>
					<p className={styles.inputTitle}>
						{t('searchFilterProfileType')}
					</p>
					<DropDown
						options={roleOptions}
						isSearchable={false}
						placeholder={t('textChooseRole')}
						handleValue={handleRole}
						value={selectedRole}
						minHeight={'48px'}
					/>
				</div>
				{selectedRole && selectedRole.value !== rolType.admin && (
					<div
						className={styles.columnInput}
						style={{ marginLeft: '30px' }}
					>
						<p className={styles.inputTitle}>
							{t('textRegisterAcademicPrograma')}
						</p>
						<DropDown
							options={programs}
							isSearchable={true}
							placeholder={t('placeholderProgram')}
							handleValue={selectProgram}
							handleInputChange={handleInputChange}
							value={program}
							minHeight={'48px'}
						/>
					</div>
				)}
				{selectedRole && selectedRole.value === rolType.student && (
					<div
						className={styles.columnInput}
						style={{ marginLeft: '30px' }}
					>
						<p className={styles.inputTitle}>
							{t('textRegisterSemester')}
						</p>
						<InputGroup
							inputName={'semester'}
							error={missingFields.includes('semester')}
							style={{
								height: '48px',
								margin: '0px',
								fontSize: '16px',
							}}
							placeholder={t('inputSemester')}
							onChange={handleOnchage}
							value={fields.semester}
						/>
					</div>
				)}
			</div>
			<div className={styles.buttonRow}>
				<Button
					height={'48px'}
					width={'300px'}
					disabled={isButtonDisabled}
					appearance={appearances.primary}
					borderRadius="6px"
					title={
						isLoading ? <ImpulseSpinner /> : t('textPreferenceSave')
					}
					action={submit}
				/>
			</div>
		</div>
	);
};

function mapStateToProps({}) {
	return {};
}

export default withRouter(
	connect(mapStateToProps, {
		EditProfileNew,
		searchProgram,
	})(ProfileSettings)
);
