import { useState , useEffect } from 'react';
const useRoleProfile = (role)=>{
	const [roleKey, setRoleKey] = useState();

	useEffect(() => {
		switch (role) {
		case 'student':
			setRoleKey('titleStudent');
			break;
		case 'teacher':
			setRoleKey('titleTeacher');
			break;
		case 'admin':
			setRoleKey('titleManager');
			break;
		case 'graduate':
			setRoleKey('titleGraduate');
			break;
		default:
			setRoleKey(role);
			break;
		}
	}, [role]);
    

	return {roleKey};
};

export default useRoleProfile;