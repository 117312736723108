import React, { useEffect, useState } from 'react';
import DropDown from '../shared/DropDown';
import { useTranslation } from 'react-i18next';
import styles from '../../pages/styles/searchV2Page.scss';
import useOptionDropDown from '../../hooks/useOptionDropDown';

import { contentTypeNew, ISO_369_1 } from '../../../utils/constans';

const FiltersComponent = ({
	filtersFromSearch,
	profilesFromSearch,
	filtersFromComponent,
	setFilters,
	setProfiles,
	isMobile,
}) => {
	const { t } = useTranslation();

	const [listEditorials, setListEditorial] = useState([]);
	const [listLanguages, setListLanguages] = useState([]);
	const [checkboxTypes, setChecboxType] = useState({});
	const [checkboxProfile, setChecboxProfile] = useState({
		student: false,
		teacher: false,
		admin: false,
		graduate: false,
	});
	const [editorials, editorial, handleEditorial, resetEditorial] =
    useOptionDropDown(listEditorials, true);
	const [languages, language, handleLaguage, resetLanguage] = useOptionDropDown(
		listLanguages,
		true
	);

	useEffect(() => {
		if (filtersFromSearch && filtersFromSearch.type) {
			let types = {};

			filtersFromSearch.type.forEach((element) => {
				types[element.name] = filtersFromComponent == element.name;
			});

			setChecboxType(types);
		}

		filtersFromSearch &&
      filtersFromSearch.editorial &&
      setListEditorial(
      	filtersFromSearch.editorial.map((editorial) => {
      		return {
      			value: editorial.name,
      			label:
              editorial.name === 'no editorial'
              	? t('textNoEditorial')
              	: editorial.name,
      		};
      	})
      );

		filtersFromSearch &&
      filtersFromSearch.language &&
      setListLanguages(
      	filtersFromSearch.language.map((lan) => {
      		const index = ISO_369_1.findIndex((item) => item.name === lan.name);
      		return {
      			value: lan.name,
      			label: t('dropDownLanguage')[index].label,
      		};
      	})
      );
	}, [filtersFromSearch]);

	useEffect(() => {
		if (editorial.length > 0 || editorial) {
			setFilters({ ...filtersFromComponent, editorial: editorial.value });
		}
	}, [editorial]);

	useEffect(() => {
		if (language.length > 0 || editorial) {
			setFilters({ ...filtersFromComponent, lang: language.value });
		}
	}, [language]);

	useEffect(() => {
		setProfiles(profilesFromSearch);
		if (checkboxProfile.admin) {
			setProfiles(
				profilesFromSearch.filter((profile) => profile.rol === 'admin')
			);
		} else if (checkboxProfile.teacher) {
			setProfiles(
				profilesFromSearch.filter((profile) => profile.rol === 'teacher')
			);
		} else if (checkboxProfile.graduate) {
			setProfiles(
				profilesFromSearch.filter((profile) => profile.rol === 'graduate')
			);
		} else if (checkboxProfile.student) {
			setProfiles(
				profilesFromSearch.filter((profile) => profile.rol === 'student')
			);
		}
	}, [checkboxProfile]);

	const handleTypeValueChange = (e) => {
		const target = e.target;
		const name = target.name;
		let types = {};
		for (const key in checkboxTypes) {
			if (key === name) {
				types[key] = target.checked;
				target.checked
					? setFilters({ ...filtersFromComponent, type: key })
					: setFilters({ ...filtersFromComponent, type: '' });
			} else {
				types[key] = false;
			}
		}
		setChecboxType(types);
	};

	const handleProfileValueChange = (e) => {
		const target = e.target;
		const name = target.name;
		const filters = {};
		for (const key in checkboxProfile) {
			if (key === name) {
				filters[key] = target.checked;
			} else {
				filters[key] = false;
			}
		}
		setChecboxProfile(filters);
	};

	const translateTypeContent = (name) => {
		switch (name) {
		case 'Libros':
			return t('textSearchFilterBook');
		case 'Libro':
			return t('textSearchFilterBook');
		case 'Artículos':
			return t('textSearchFilterArticle');
		case 'Articulo':
			return t('textSearchFilterArticle');
		case 'Revistas':
			return t('textSearchFilterPaper');
		case 'Perfiles':
			return t('textSearchFilterProfiles');
		case 'Booklists':
			return t('textSearchFilterBooklist');
		case 'Videos':
			return t('textSearchResultVideo');
		case 'Textos':
			return t('textSearchResultText');
		case 'Imágenes':
			return t('textSearchResultImagen');
		case 'Libro fisico':
			return t('textSearchResultOfflineBook');
		case 'Enlace':
			return t('textSearchFilterLink');
		case 'Libros':
			return t('textSearchFilterBook');
		case 'Libro externo':
			return t('textSearchResultExternalBook');
		case 'Documento':
			return t('textSearchFilterDocument');
		case 'Enlace':
			return t('textSearchFilterLink');
		default:
			return name;
		}
	};
	return (
		<>
			<div
				style={
					isMobile
						? null
						: {
								height: '100vh',
								position: 'absolute',
								width: '20vw',
								maxWidth: '280px',
						  }
				}
				className={styles.boxPeople}
			>
				<h2 className={styles.fontTitle}>{t('titleSearchFilters')}</h2>
				<div className={isMobile ? styles.filtersContainerMobile : null}>
					<div style={{ marginTop: '30px' }}>
						<DropDown
							title={t('searchFilterSource')}
							listOptions={listEditorials}
							maxWidth={'218px'}
							width={isMobile ? '180px' :'218px'}
							minHeight={35}
							fontSizeTitle={'15px'}
							fontSizeDropDown={14}
							options={editorials}
							value={editorial}
							handleValue={handleEditorial}
							boxShadow={'0 2px 6px 0 rgba(0, 0, 0, 0.1)'}
							borderRadius={4}
						/>
					</div>
					<div style={{ marginTop: '30px' }}>
						<DropDown
							title={t('searchFilterLang')}
							listOptions={listLanguages}
							maxWidth={'218px'}
							width={isMobile ? '180px' :'218px'}
							minHeight={35}
							fontSizeTitle={'15px'}
							fontSizeDropDown={14}
							options={languages}
							value={language}
							handleValue={handleLaguage}
							boxShadow={'0 2px 6px 0 rgba(0, 0, 0, 0.1)'}
							borderRadius={4}
						/>
					</div>
				</div>
				<div className={styles.containerFilter}>
					<h3 className={styles.titleFilter}>
						{t('searchFilterProfileType')}
					</h3>
					<div className={
						isMobile ? 
							styles.containerRadioButtonsMobile : 
							styles.containerRadioButtons
					}>
						<div style={{marginRight: '10px',}}>
							<div className={styles.containerRadioButton}>
								<div className={styles.radio}>
									<input
										type="checkbox"
										onChange={(e) =>
											handleProfileValueChange(e)
										}
										name={'student'}
										checked={checkboxProfile.student}
									/>
								</div>
								<label
									className={`${styles.fontp} ${styles.cursor}`}
								>
									{t('searchFilterProfileStudent')}
								</label>
							</div>
							<div className={styles.containerRadioButton}>
								<div className={styles.radio}>
									<input
										type="checkbox"
										onChange={(e) =>
											handleProfileValueChange(e)
										}
										name={'teacher'}
										checked={checkboxProfile.teacher}
									/>
								</div>
								<label
									className={`${styles.fontp} ${styles.cursor}`}
								>
									{t('searchFilterProfileProfessor')}
								</label>
							</div>
						</div>
						<div>
							<div className={styles.containerRadioButton}>
								<div className={styles.radio}>
									<input
										type="checkbox"
										onChange={(e) =>
											handleProfileValueChange(e)
										}
										name={'admin'}
										checked={checkboxProfile.admin}
									/>
								</div>
								<label
									className={`${styles.fontp} ${styles.cursor}`}
								>
									{t('searchFilterProfileStaff')}
								</label>
							</div>
							<div className={styles.containerRadioButton}>
								<div className={styles.radio}>
									<input
										type="checkbox"
										onChange={(e) =>
											handleProfileValueChange(e)
										}
										name={'graduate'}
										checked={checkboxProfile.graduate}
									/>
								</div>
								<label
									className={`${styles.fontp} ${styles.cursor}`}
								>
									{t('searchFilterProfileGraduate')}
								</label>
							</div>
						</div>
					</div>
				</div>
				<div className={styles.containerFilter}>
					<h3 className={styles.titleFilter}>
						{t('searchFilterDocType')}
					</h3>
					<div className={
						isMobile ? 
							styles.containerRadioButtonsMobile : 
							styles.containerRadioButtons
					}>
						{filtersFromSearch &&
							filtersFromSearch.type &&
							filtersFromSearch.type.map((type, index) => {
								return (
									<div className={styles.containerRadioButton} key={index}>
										<div
											className={styles.radio}
										>
											<input
												name={type.name}
												type="checkbox"
												onChange={(e) =>
													handleTypeValueChange(e)
												}
												checked={checkboxTypes[type.name]}
											/>
										</div>
										<label
											className={`${styles.fontp} ${styles.cursor}`}
										>
											{/* Definir logica para eliminarl a necesidad del metodo translateTypeContent */}
											{translateTypeContent(
												contentTypeNew[type.name].label
											)}
										</label>
									</div>
								);
							})}
					</div>
				</div>
			</div>
		</>
	);
};

export default FiltersComponent;
